import Login from '@/views/LoginPage.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/cadastro-prestadores',
    name: 'CadastroPrestadores',
    component: () => import('@/views/Cadastros/CadastroPrestadores.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: ''
    }
  },

  {
    path: '/admin/espacos-list',
    name: 'ListEspacoConfinado',
    component: () =>
      import('@/views/espacos_confinados/ListagemEspacoConfinados.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'espaco_confinado_listar'
    }
  },
  {
    path: '/admin/espacos-edit/:id',
    name: 'EditEspacoConfinado',
    component: () =>
      import('@/views/espacos_confinados/EditarEspacoConfinados.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'espaco_confinado_editar'
    },
    props: true
  },
  {
    path: '/templates/email',
    name: 'TemplatesEmail',
    component: () => import('@/views/EditorTemplates.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'usuario_listar'
    }
  },
  {
    path: '/usuarios/listagem',
    name: 'UsersList',
    component: () => import('@/views/usuarios/ListagemUsuarios.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'usuario_listar'
    }
  },
  {
    path: '/usuarios/editar/:id',
    name: 'EditarUsuario',
    component: () => import('@/views/usuarios/Edit_Usuario.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'usuario_editar'
    },
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/redefinir-senha',
    name: 'ResetPassword',
    component: () => import('@/views/ChangePassword.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterUser.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard/DashboardPage.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'dashboard'
    }
  },
  {
    path: '/tabelas-auxiliares/pontos-ancoragem',
    name: 'PontosAncoragem',
    component: () => import('@/views/TabelasAuxiliares/PontosAncoragem.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'ponto_ancoragem_listar'
    }
  },
  {
    path: '/tabelas-auxiliares/acionamento-resgate',
    name: 'AcionamentoResgate',
    component: () => import('@/views/TabelasAuxiliares/AcionamentoResgate.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'acionamento_resgate_listar'
    }
  },
  {
    path: '/tabelas-auxiliares/equipamento-minimo',
    name: 'EquipamentoMinimo',
    component: () => import('@/views/TabelasAuxiliares/EquipamentoMinimo.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'equipamento_minimo_listar'
    }
  },
  {
    path: '/tabelas-auxiliares/localizacao',
    name: 'Localizacoes',
    component: () =>
      import('@/views/TabelasAuxiliares/LocalizacoesEspacos.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'localizacao_listar'
    }
  },
  {
    path: '/tabelas-auxiliares/motivos-pet',
    name: 'MotivosPet',
    component: () => import('@/views/TabelasAuxiliares/MotivosPet.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'motivo_pet_listar'
    }
  },
  {
    path: '/tabelas-auxiliares/procedimento-padrao',
    name: 'ProcedimentoPadrao',
    component: () => import('@/views/TabelasAuxiliares/ProcedimentoPadrao.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'procedimento_padrao_listar'
    }
  },
  {
    path: '/tabelas-auxiliares/tipos-espacos',
    name: 'TiposEspacos',
    component: () => import('@/views/TabelasAuxiliares/TiposEspacos.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'tipo_espaco_listar'
    }
  },
  {
    path: '/tabelas-auxiliares/categorias',
    name: 'Categorias',
    component: () => import('@/views/TabelasAuxiliares/TabelaCategorias.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'categoria_listar'
    }
  },
  {
    path: '/tabelas-auxiliares/posicao-acesso',
    name: 'PosicaoAcesso',
    component: () => import('@/views/TabelasAuxiliares/PosicaoAcesso.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'posicao_acesso_listar'
    }
  },
  {
    path: '/permissao-trabalho/inclusao',
    name: 'InclusaoPermissaoTrabalho',
    component: () =>
      import('@/views/PermissaoTrabalhoSeguro/InclusaoPermissaoTrabalho.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'permissao_trabalho_incluir'
    }
  },
  {
    path: '/permissao-entrada-trabalho/inclusao',
    name: 'InclusaoPermissaoEntradaTrabalho',
    component: () =>
      import(
        '@/views/PermissaoEntradaTrabalho/InclusaoPermissaoEntradaTrabalho.vue'
      ),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'permissao_entrada_trabalho_incluir'
    }
  },
  {
    path: '/authentication/mfa-auth',
    name: 'MfaAuth',
    component: () => import('@/views/MFALoginPage.vue')
  },
  {
    path: '/configuracoes/cadastro-checklist',
    name: 'CadastroChecklist',
    component: () => import('@/views/CadastroChecklist.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'checklist_listar'
    }
  },

  {
    path: '/permissao-entrada/preenchimento-pet/:id',
    name: 'PreenchimentoPet',
    component: () =>
      import('@/views/PermissaoEntradaTrabalho/PreenchimentoPet.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'permissao_entrada_trabalho_preencher'
    },
    props: true
  },
  {
    path: '/configuracoes/cadastro-questoes-checklist/:id',
    name: 'CadastroQuestoesChecklist',
    component: () => import('@/views/CadastroQuestoesChecklist.vue'),
    props: true,
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'checklist_editar'
    }
  },
  {
    path: '/permissao-entrada/preenchimento-checklist/:id',
    name: 'PreencimentoChecklist',
    component: () =>
      import('@/views/PermissaoEntradaTrabalho/PreenchimentoChecklist.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'permissao_entrada_trabalho_preencher'
    },
    props: true
  },
  {
    path: '/permissao-entrada/dashboard',
    name: 'DashboardPet',
    component: () =>
      import('@/views/PermissaoEntradaTrabalho/DashboardPet.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'permissao_entrada_trabalho_dashboard'
    },
    props: true
  },
  {
    path: '/permissao-entrada/assinaturas/:id',
    name: 'AssinaturasPet',
    component: () =>
      import('@/views/PermissaoEntradaTrabalho/AssinaturasPet.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'permissao_entrada_trabalho_assinar'
    },
    props: true
  },
  {
    path: '/permissao-entrada/pet-report/:id',
    name: 'EmissaoPetPdf',
    component: () =>
      import('@/views/PermissaoEntradaTrabalho/EmissaoPetPdf.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'permissao_entrada_trabalho_gerar_pdf'
    },
    props: true
  },
  {
    path: '/cadastros/fornecedores',
    name: 'CadastroFornecedor',
    component: () => import('@/views/Cadastros/CadastroFornecedor.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'fornecedor_listar'
    },
    props: true
  },
  {
    path: '/cadastro/portaria',
    name: 'Portaria',
    component: () => import('@/views/Cadastros/CadastroPortaria.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'portaria_listar'
    },
    props: true
  },
  {
    path: '/cadastros/fornecedores/:id',
    name: 'CadastroFornecedorDetalhe',
    component: () => import('@/views/Cadastros/CadastroFornecedorDetalhe.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'fornecedor_editar'
    },
    props: true
  },
  {
    path: '/cadastros/convites-enviados',
    name: 'ConvitesEnviados',
    component: () => import('@/views/Cadastros/RelatorioConvitesEnviados.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'convite_listar'
    },
    props: true
  },
  {
    path: '/cadastros/convites-enviados/:contractorId',
    name: 'ContractorDetail',
    component: () => import('@/views/Cadastros/ContractorDetail.vue'),
    meta: {
      layout: 'AppLayoutAdmin',
      protected: true,
      permissions: 'convite_editar'
    },
    props: true
  },
  {
    path: '/unautorized',
    name: 'Unautorized',
    component: () => import('@/views/UnautorizedView.vue'),
    meta: { layout: 'AppLayoutAdmin', protected: true }
  },
  {
    path: '/colaboradores-colgate',
    name: 'ColaboradoresColgate',
    component: () => import('@/views/ColaboradoresColgatePage.vue'),
    meta: { layout: 'AppLayoutAdmin', protected: true }
  },
  {
    path: '/colaboradores-colgate/:id',
    name: 'ColaboradorColgateDetail',
    component: () => import('@/views/ColaboradorColgateDetail.vue'),
    meta: { layout: 'AppLayoutAdmin', protected: true }
  },
  {
    path: '/colaboradores-prestadores',
    name: 'ColaboradoresPrestadores',
    component: () => import('@/views/ColaboradoresPrestadoresPage.vue'),
    meta: { layout: 'AppLayoutAdmin', protected: true }
  },
  {
    path: '/colaboradores-prestadores/:id',
    name: 'ColaboradorPrestadorDetail',
    component: () => import('@/views/ColaboradorPrestadorDetail.vue'),
    meta: { layout: 'AppLayoutAdmin', protected: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authenticationStore =
    localStorage.getItem('authenticationStore') || '{}'
  const authentication = JSON.parse(authenticationStore)
  const userAuthenticated = authentication.userAuthenticated
  const intentedRoute = to.path
  const loginPath = '/login'
  const logoutPath = '/logout'
  const mfaAuthPath = '/authentication/mfa-auth'

  const resetPasswordPath = '/redefinir-senha'
  const forgotPasswordPath = '/forgot-password'
  const registerPath = '/register'

  const isPublicRoute =
    intentedRoute === resetPasswordPath ||
    intentedRoute === forgotPasswordPath ||
    intentedRoute === registerPath
  const hasResetPasswordToken =
    intentedRoute.startsWith(resetPasswordPath) && to.query.token

  if (isPublicRoute || hasResetPasswordToken) {
    next()
  } else if (intentedRoute === logoutPath) {
    localStorage.removeItem('authenticationStore')
    next({ path: loginPath })
  } else if (intentedRoute === loginPath || intentedRoute === mfaAuthPath) {
    userAuthenticated ? next({ path: '/' }) : next()
  } else {
    userAuthenticated
      ? next()
      : next({ path: loginPath, query: { redirect: intentedRoute } })
  }
})

export default router
